<template>
  <v-menu :close-on-content-click="loading" v-model="menu" min-width="500px" offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <span v-if="limitFilterType === 'budget_limits'" v-bind="attrs"
                v-on="{ ...tooltip, ...menu }">{{ Math.round(limitFilter_dailyLimit / 100) }}</span>
          <span v-else-if="limitFilterType === 'clicks_limits'" v-bind="attrs" v-on="{ ...tooltip, ...menu }"><v-icon
              class="red--text pr-1">mdi-cursor-default-click</v-icon>{{ limitFilter_dailyLimit }}</span>
          <span v-else v-bind="attrs" v-on="{ ...tooltip, ...menu }"><v-icon
              class="red--text">mdi-alert-octagon-outline</v-icon></span>
          <!--          <v-btn-->
          <!--              color="primary"-->
          <!--              dark-->
          <!--              v-bind="attrs"-->
          <!--              v-on="{ ...tooltip, ...menu }"-->
          <!--          >-->
          <!--            Dropdown w/ Tooltip-->
          <!--          </v-btn>-->
        </template>
        <div>
          <span v-if="limitFilterType === 'budget_limits'">Budget by $</span>
          <span v-else-if="limitFilterType === 'clicks_limits'">Budget by Clicks</span>
          <span v-else>Unlimited</span>
        </div>
      </v-tooltip>
    </template>
    <v-card>
      <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
        <v-card>
          <v-card-title class="red">
            <span class="headline">API Error</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p class="text-h6">{{ errorMessage }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--      <v-list>-->
      <!--        <v-list-item>-->
      <!--          <v-list-item-avatar>-->
      <!--            <img-->
      <!--                src="https://cdn.vuetifyjs.com/images/john.jpg"-->
      <!--                alt="John"-->
      <!--            >-->
      <!--          </v-list-item-avatar>-->

      <!--          <v-list-item-content>-->
      <!--            <v-list-item-title>John Leider</v-list-item-title>-->
      <!--            <v-list-item-subtitle>Founder of Vuetify</v-list-item-subtitle>-->
      <!--          </v-list-item-content>-->

      <!--          <v-list-item-action>-->
      <!--            <v-btn-->
      <!--                :class="fav ? 'red&#45;&#45;text' : ''"-->
      <!--                icon-->
      <!--                @click="fav = !fav"-->
      <!--            >-->
      <!--              <v-icon>mdi-heart</v-icon>-->
      <!--            </v-btn>-->
      <!--          </v-list-item-action>-->
      <!--        </v-list-item>-->
      <!--      </v-list>-->

      <!--      <v-divider></v-divider>-->

      <v-form v-model="valid" ref="form">
        <v-list>
          <v-list-item>
            <v-list-item-title class="subtitle-1">Limit by</v-list-item-title>
            <v-list-item-action>
              <v-select
                  v-model="type"
                  :items="types"
                  hide-details
                  dense
                  solo

              >
                <template v-slot:item="{ item }">
                  <v-icon class="pr-2">{{ item.icon }}</v-icon>
                  {{ item.text }}
                </template>

                <template v-slot:selection="{ item }">
                  <v-icon class="pr-2">{{ item.icon }}</v-icon>
                  {{ item.text }}
                </template>
              </v-select>
            </v-list-item-action>

          </v-list-item>

          <v-list-item>
            <v-list-item-title class="pr-3">
              <v-text-field
                  v-model="dailyLimits"
                  label="Daily limit"
                  required
                  :rules="[validateDailyLimits]"></v-text-field>
            </v-list-item-title>
            <v-list-item-title class="pl-3">
              <v-text-field
                  v-model="overallLimit"
                  :rules="[validateOverallLimits]"
                  required
                  label="Overall limits"
              ></v-text-field>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="type === 'clicks_limits'">
            <v-checkbox label="Split the budget evenly throughout the day " v-model="evenly"></v-checkbox>
          </v-list-item>

          <!--        <v-list-item>-->
          <!--          <v-list-item-action>-->
          <!--            <v-switch-->
          <!--                v-model="hints"-->
          <!--                color="purple"-->
          <!--            ></v-switch>-->
          <!--          </v-list-item-action>-->
          <!--          <v-list-item-title>Enable hints</v-list-item-title>-->
          <!--        </v-list-item>-->
        </v-list>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            :loading="loading"
            @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
            color="green"
            :disabled="!allowSave || loading"
            @click="onUpdate"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--    <v-list>-->

    <!--      <v-list-item-->
    <!--          v-for="(item, index) in items"-->
    <!--          :key="index"-->
    <!--      >-->
    <!--        <v-list-item-title>{{ item.title }}</v-list-item-title>-->
    <!--      </v-list-item>-->
    <!--    </v-list>-->
  </v-menu>
</template>


<script>
import api from "@/utils/api"

export default {
  name: "LimitsEditor",
  props: {
    client_id: Number,
    campaignId: Number,
    limitFilterType: String,
    limitFilter_dailyLimit: Number,
    limitFilter_overallLimit: Number,
    limitFilter_splitDailyLimitEvenly: Number
  },
  data() {
    return {
      type: this.limitFilterType,
      menu: false,
      types: [
        {
          value: 'budget_limits',
          text: 'budget, $',
          icon: 'mdi-cash'
        },
        {
          value: 'clicks_limits',
          text: 'clicks',
          icon: 'mdi-cursor-default-click'
        }
      ],
      dailyLimits: this.limitFilterType === 'budget_limits' ? this.limitFilter_dailyLimit / 100 : this.limitFilter_dailyLimit,
      overallLimit: this.limitFilterType === 'budget_limits' ? this.limitFilter_overallLimit / 100 : this.limitFilter_overallLimit,
      evenly: this.limitFilterType === 'clicks_limits' ? this.limitFilter_splitDailyLimitEvenly === 1 : false,
      valid: false,
      loading: false,
      isVisibleValidationErrorDialog: false,
      errorMessage: ''
    }
  },
  computed: {
    allowSave() {
      if (this.valid) {
        if (this.type !== this.limitFilterType) {
          return true
        }
        if (this.type === 'budget_limits') {
          if (this.dailyLimits * 100 !== this.limitFilter_dailyLimit || this.overallLimit * 100 !== this.limitFilter_overallLimit) {
            return true
          }
        } else {
          if (this.dailyLimits !== this.limitFilter_dailyLimit || this.overallLimit !== this.limitFilter_overallLimit) {
            return true
          }
        }

        if (this.type === 'clicks_limits' && ((this.limitFilter_splitDailyLimitEvenly === 1) !== this.evenly)) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    validateDailyLimits(value) {
      if (this.type === 'clicks_limits') {
        if (value > 500) {
          return true
        } else {
          return 'Must be more 500'
        }
      } else if (this.type === 'budget_limits') {
        if (value > 10) {
          return true
        } else {
          return 'Must be more 10'
        }
      }
      return false
    },
    validateOverallLimits(value) {
      const dailyLimits = Number(this.dailyLimits)
      if (this.type === 'clicks_limits' && (value > dailyLimits || value === '' || value === 0)) {
        return true
      } else if (this.type === 'budget_limits' && (value > dailyLimits || value === '' || value === 0)) {
        return true
      }
      return 'Must be more than a "Daily limit" or 0/empty'
    },

    async onUpdate() {
      this.loading = true
      const overallLimit = Number(this.overallLimit)

      const data = {
        network: 'mgid',
        client_id: this.client_id,
        campaign_id: this.campaignId,
        limitType: this.type,
        dailyLimit: this.type === 'budget_limits' ? this.dailyLimits : this.dailyLimits,
        overallLimit: typeof overallLimit === 'number' && this.overallLimit > 0 ? this.overallLimit : undefined,
        splitDailyLimitEvenly: this.type === 'clicks_limits' ? this.limitFilter_splitDailyLimitEvenly === 1 : undefined
      }

      try {
        const r = await api.optimizationUpdateCampaignLimit(data)
        console.log(r)
        this.menu = false
        this.$emit('update', true)
        this.loading = false
      } catch (e) {
        console.error(e)
        this.errorMessage = JSON.stringify(e, null, 2)
        this.isVisibleValidationErrorDialog = true
        this.loading = false
      }

      // await new Promise(resolve => setTimeout(resolve, 5000))


    }
  },
  watch: {
    type() {
      this.$refs.form.validate()
    }
  }
}
</script>


<style scoped>

</style>